import styled from "styled-components";
import { device } from "../../styles/deviceSizes";
import { LIGHT_BG, DARK_BG } from "../../constants";
export const FooterStyles = styled.div`
  width: 100%;
  position: relative;
  /* text-align: center; */
  height: 77rem;
  opacity: 1;
`;

export const StyledFooter = styled.footer`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${({ bg }) =>
    bg === DARK_BG
      ? "#000000"
      : bg === LIGHT_BG
      ? "transparent"
      : // "linear-gradient(180deg, #FFFFFF 7.81%, rgba(247, 247, 247, 0) 100%)"
        "#000000"};
  opacity: ${({ bg }) => (bg === DARK_BG ? 0.9 : bg === LIGHT_BG ? 1 : 0.9)};
  text-align: left;
  color: ${({ bg }) =>
    bg === DARK_BG ? "white" : bg === LIGHT_BG ? "#404040" : "white"};
  letter-spacing: 0.075em;
  font-family: "Inter";
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  section {
    width: 100%;
  }

  .footer-copyright {
    margin-top: 0.8rem;
    text-align: left;
    @media ${device.onlyMobileSm} {
      text-align: center;
    }
  }

  h3 {
    color: ${({ bg }) =>
      bg === DARK_BG ? "white" : bg === LIGHT_BG ? "#404040" : "white"};
    // font: normal 36px "Open Sans", cursive;
    margin: 0;
    font-size: 3.2rem;
    text-align: center;
    font-weight: 700;
    margin: 227px 0 11.5rem 0;

    @media ${device.onlyTabletAndLaptopSm} {
      margin: 5rem 0 5rem 0;
    }

    @media ${device.onlyMobileS} {
      margin: 5rem 0 5rem 0;
    }
  }

  hr {
    margin: unset;
  }
  .content-wrapper {
    /* padding: 2.4rem 0; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    line-height: 2em;
  }
  .section-container {
    justify-content: unset;
  }
`;

export const FooterContentList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  column-gap: 11.5rem;
  row-gap: 2rem;
  /* width: 100%; */
  padding-bottom: 96px;
  @media ${device.onlyTableSm} {
    justify-content: center;
    column-gap: 5rem;
  }
  @media ${device.onlyMobileSm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    column-gap: 2rem;
    row-gap: 2rem;
    padding-bottom: 5rem;
    .nav__item {
      width: 100%;
      text-align: center;
    }
  }
  .nav__title {
    font-size: 2rem;
    font-weight: 700;
    @media ${device.onlyMobileSm} {
      font-size: 1.6rem;
    }
  }

  .nav__ul {
    li {
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 3rem;
    }
  }
`;

export const MainSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  .main-content-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
`;

export const BottomSection = styled.section`
  display: flex;
  font-size: 1.2rem;
  .bottom-content-wrapper {
    width: 100%;
    /* margin: 0rem 11rem; */
    @media ${device.onlyTabletAndLaptopSm} {
      margin: unset;
    }
    @media ${device.onlyMobileSm} {
      margin: unset;
    }
  }
  .content {
    margin-top: 0.8rem;
    .inner-wrapper {
      display: flex;
      flex-direction: row;
      @media ${device.onlyMobileSm} {
        flex-direction: column;
      }
      .links {
        display: flex;
        column-gap: 1.3rem;
        width: 100%;
        @media ${device.onlyMobileSm} {
          flex-wrap: wrap;
          /* justify-content: space-between; */
          align-items: center;
          /* column-gap: 2.5rem; */
          /* justify-content: space-between; */
        }
      }
    }
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  column-gap: 1.3rem;
  width: 100%;
  justify-content: flex-end;
  margin: 0;
  @media ${device.onlyMobileSm} {
    justify-content: center;
    margin: 1rem 0;
  }
  .social-links {
    display: grid;
    justify-self: flex-end;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
    @media ${device.onlyMobileS} {
      justify-self: flex-start;
    }
  }

  .social-links svg {
    width: 30px;
    height: 30px;
    fill: ${({ bg }) =>
      bg === DARK_BG ? "white" : bg === LIGHT_BG ? "#404040" : "white"};
  }
`;
