import React from "react";
import { Link } from "gatsby";
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Twitter from "../../assets/icons/twitter.svg";
import {
  RESORTS,
  COLLECTIONS,
  COUPLES_COLLECTION,
  WATER_VILLA_COLLECTION,
  MAGAZINE,
  RESORT_COLLECTION,
  BEACH_VILLA_COLLECTION,
  LARGE_COLLECTION,
  SIMPLE_MAIN_NAVBAR,
  LIGHT_BG,
  DARK_BG,
  SHOW_PRICING_MODAL,
  SHOW_CONTACT_US,
} from "../../constants";
import {
  FixedBackgroundImage,
  SectionContainer,
  usePricingContext,
} from "../../components";

import { useNavBar } from "../../hooks";
import {
  FooterStyles,
  FooterContentList,
  MainSection,
  SocialLinksWrapper,
  BottomSection,
  StyledFooter,
} from "./elements";

const footerData = {
  rows: [
    {
      title: {
        name: "About",
      },
      items: [
        {
          name: "Home",
          link: "/",
        },
        { name: "About US" },
        { name: "Contact", showContactUs: true },
        {
          name: RESORTS,
          openSideBar: true,
        },
      ],
    },
    {
      title: {
        name: "General",
      },
      items: [
        { name: MAGAZINE, link: "/magazine" },
        { name: COLLECTIONS, openSideBar: true },
        { name: "FAQ", link: "/faq" },
        { name: "Request An Invoice", triggerPricingModal: true },
      ],
    },
    {
      title: {
        name: "Collection",
      },
      items: [
        { name: RESORT_COLLECTION, link: "/collections/resort-collection" },
        { name: COUPLES_COLLECTION, link: "/collections/couples-collection/" },
        {
          name: WATER_VILLA_COLLECTION,
          link: "/collections/top-water-villas",
        },
        { name: BEACH_VILLA_COLLECTION, link: "/collections/beach-villas" },
        { name: LARGE_COLLECTION, link: "/collections/large-villas" },
      ],
    },
    {
      title: {
        name: "More",
      },
      items: [
        {
          name: "Private Events",
          link: "/enquire",
        },
        { name: "Private Island Bookings", link: "/enquire" },
        { name: "Private JET HIRE", link: "/enquire" },
      ],
    },
  ],
};

export const Footer = ({ contactUs }) => {
  const { pageName, setActiveNavLink, setShowLeftSideBar } = useNavBar();
  const { dispatch } = usePricingContext();

  const onLinkClick = (name) => {
    setActiveNavLink(name);
    setShowLeftSideBar(true);
  };

  const onInvoiceRequest = () => {
    dispatch({ type: SHOW_PRICING_MODAL });
  };

  const onContactUsClick = () => {
    dispatch({ type: SHOW_CONTACT_US });
  };

  const footerBackground = pageName === SIMPLE_MAIN_NAVBAR ? DARK_BG : LIGHT_BG;

  return (
    <React.Fragment>
      <FooterStyles>
        <FixedBackgroundImage
          bgImage={
            pageName === SIMPLE_MAIN_NAVBAR
              ? "https://cdn.sanity.io/images/y7yu20xn/master/b8f70101fee25581e144abef96e824615ea7c770-3024x4032.webp"
              : "https://cdn.sanity.io/images/y7yu20xn/master/af46fdc14ee8dea095d6e9a6d8387f183a66db24-4320x2292.png"
            // "https://cdn.sanity.io/images/y7yu20xn/master/a361af579b76a62fe8f87702f54fb8a5579b4a3c-2880x1528.png"
          }
        />

        <StyledFooter
          bg={footerBackground}
          // className="footer-distributed"
          // style={{ maxHeight: pageYOffset > 8500 ? "77rem" : "0" }}
        >
          <SectionContainer className="section-container">
            <MainSection>
              <div className="main-content-wrapper">
                <h3>
                  BOUNDLESS<span> MALDIVES</span>
                </h3>
                <FooterContentList>
                  {footerData?.rows?.map(({ title, items }) => {
                    return (
                      <React.Fragment>
                        <li class="nav__item">
                          <div className="nav__title">{title?.name}</div>
                          <ul class="nav__ul">
                            {items?.length &&
                              items?.map(
                                ({
                                  name,
                                  link,
                                  openSideBar,
                                  showContactUs,
                                  triggerPricingModal,
                                }) => {
                                  const item = link ? (
                                    <Link to={link}>{name}</Link>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        if (openSideBar) onLinkClick(name);
                                        if (showContactUs) {
                                          onContactUsClick();
                                        }
                                        if (triggerPricingModal)
                                          onInvoiceRequest();
                                      }}
                                    >
                                      {name}
                                    </a>
                                  );

                                  return <li>{item}</li>;
                                }
                              )}
                          </ul>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </FooterContentList>
              </div>
            </MainSection>
            <BottomSection>
              <div className="bottom-content-wrapper">
                <hr />
                <div className="content">
                  <div className="inner-wrapper">
                    <div className="links">
                      <div>Legal Notice</div>
                      <div> | </div>
                      <div>Privacy notice</div>
                      <div> | </div>
                      <div>Cookies Preferences</div>
                      <div> | </div>
                      <div>Accesibility Policy</div>
                    </div>
                    <SocialLinksWrapper bg={footerBackground}>
                      <div className="social-links">
                        <a
                          href="https://twitter.com/boundlessmv"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Twitter />
                        </a>
                        <a
                          target="_blank"
                          href="https://m.facebook.com/Boundless-Maldives-109861517849092/"
                          rel="noreferrer"
                        >
                          <Facebook />
                        </a>
                        <a
                          href="https://www.instagram.com/boundlessmaldives/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Instagram />
                        </a>
                      </div>
                    </SocialLinksWrapper>
                  </div>
                  <div className="footer-copyright">
                    Boundless Maldives © 2022
                  </div>
                </div>
              </div>
            </BottomSection>
          </SectionContainer>
        </StyledFooter>
      </FooterStyles>
    </React.Fragment>
  );
};
