import styled, { css } from "styled-components";
import { device } from "../../styles/deviceSizes";

const activeStyles = css`
  .text {
    /* transition: height 5s ease-in-out 3s; */
    ::after {
      display: block;
      height: 0;
      opacity: 0;
      background-color: #fff;
      content: "";
      position: relative;
      top: 1.7rem;
      pointer-events: none;
      transition: opacity 1s ease-in-out 0s;
      width: 100%;
    }

    &.active {
      @media ${device.onlyMobileSm} {
        ::after {
          content: none !important;
        }
      }
      position: relative;
      top: 1px;
      ::after {
        opacity: 1;
        height: 2px;
      }
    }
  }
`;

export const NavWrapper = styled.nav`
  position: ${({ bottomNav }) => {
    return bottomNav ? "relative" : "fixed";
  }};
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  z-index: 2;
  .container {
    grid-template-columns: 1fr 2fr 1fr;
    @media ${device.onlyMobileS} {
      margin: 0 1.5rem !important;
      grid-template-columns: 1fr 2fr 1fr;
    }
    @media ${device.mobileL} {
      grid-template-columns: 0.5fr 2fr 0.5fr;
    }
  }

  .hamburger-wrapper {
    width: 100%;
    cursor: pointer;
    svg {
      width: 2rem;
      fill: white;
      stroke: white;
      stroke-width: 2px;
    }
  }
  ul {
    list-style: none;
  }

  &.blur-bg {
    backdrop-filter: blur(20px);
    background-color: rgba(21, 21, 21, 0.3);
    -webkit-backdrop-filter: blur(20px);
    transform: matrix(1, 0, 0, 1, 0, 0);
    height: 6rem;
    opacity: 1;
    @media ${device.onlyMobileSm} {
      background-color: rgba(21, 21, 21, 0.9);
    }
  }

  /* @media ${device.onlyMobileSm} {
    position: relative;
    background-color: rgba(21, 21, 21);
  } */

  &.secondary-nav {
    .container {
      grid-template-columns: 1fr 1.5fr;
      &.full-column {
        grid-template-columns: 0.5fr 4fr;
        @media ${device.onlyMobileSm} {
          display: flex;
          justify-content: flex-start;
        }
        .hasDropDown {
          position: relative;
          display: flex;
          align-items: center;
        }
        .hasDropDown,
        .hasSubNav {
          svg {
            margin-left: 4px;
            margin-top: -1px !important;
            height: 14px !important;
            width: 14px !important;
            filter: brightness(0.5) invert(1);
          }
          .text {
            margin-left: 10px;
          }
        }
      }

      &.three-columns {
        grid-template-columns: 0.5fr 1.5fr 3fr;
        .resort-name {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
        @media ${device.onlyMobileSm} {
          grid-template-columns: 2fr 1fr 0.1fr !important;
          gap: 2rem;
          .bottom-links {
            width: auto;
            justify-content: unset !important;
            .text {
              margin-left: 0.2rem;
              text-align: right;
            }
          }
          li {
            margin-left: 0;
            font-size: 1rem;
            width: 100%;
          }
          .resort-name {
            text-align: left;
          }
          .list-icon {
            margin-left: 0px !important;
            svg {
              height: 12px !important;
              width: 12px !important;
              margin-top: -3px !important;
            }
          }
        }
      }

      .bottom-links {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        li:not(.vertical-divider) {
          .text {
            width: 100%;
            text-align: center;
          }
        }
        li:not(.hasDropDown) {
          ::after {
            display: block;
            width: 100%;
            height: 0px;
            opacity: 0;
            background-color: #fff;
            content: "";
            position: relative;
            top: 1.7rem;
            pointer-events: none;
            transition: all 2s;
          }
          &.active {
            ::after {
              opacity: 1;
              height: 2px;
            }
          }
        }
        &.three-columns {
          .vertical-divider {
            color: black !important;
            margin: 0rem 1rem;
          }
          li {
            :not(.vertical-divider) {
              margin: unset !important;
            }
          }
        }
        &.align-items-center {
          justify-content: center;
        }
      }
    }
    &.bg-white {
      background: white;
      height: 4rem;
      .text {
        color: black;
      }
      svg {
        filter: invert(50%) brightness(0) !important;
      }
      box-shadow: 0 5px 4px -5px #222;
    }
    .page-title .text {
      margin-left: 10px;
    }
  }

  .divider {
    height: 1px;
    border-top: 1px solid;
    grid-row: 2;
    grid-column: span 5;
  }
`;

export const LogoTextWrapper = styled.div`
  width: 100%;
  z-index: 2;
  font-size: 1.5rem;
  text-align: center;
  @media ${device.onlyMobileS} {
    font-size: 1.2rem;
  }
  cursor: pointer;
`;

export const SecondaryNavBarWrapper = styled.div`
  position: relative;
  background: white;
  height: 0rem;
  transition: all 0.4s ease 0s, height 0.4s ease 0s, ball-bounce 0.4s;
  &.black-bg {
    .text,
    .page-title,
    .hasSubNav,
    .hasDropDown {
      color: white !important;
    }
    svg {
      filter: brightness(0.5) invert(1);
    }
  }

  box-shadow: 0 5px 4px -5px #222;
  .container {
    display: flex !important;
    justify-content: flex-start !important;
    @media ${device.onlyMobileSm} {
      margin: 0rem 1.5rem !important;
      flex-direction: column;
      li {
        display: flex !important;
        align-self: flex-start !important;
        margin-bottom: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid var(--lightGrey);
        width: 100%;
        font-weight: bold;
      }
    }

    li {
      &.image-wrapper {
        width: 100%;
        height: 100%;
        ::after {
          content: none !important;
        }
      }
      color: #222 !important;
      transition: all 0.4s, height 0.4s;
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      margin-left: unset !important;
      margin-right: 25px;
      .list-icon {
        margin-right: 5px;
        svg {
          margin-top: -2px;
          height: 12px !important;
          width: 12px !important;
          transform: rotate(0);
          transition: all 0.4s ease-in-out;
        }
      }
      &.active {
        ::after {
          background-color: #222 !important;
        }
      }
      &.rotate {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .second-nav-border-bottom {
    border-bottom: 1px solid #222;
  }
  .carousel {
    width: 100%;
    height: 100%;
    animation-name: ball-bounce;
    animation-timing-function: ease-out;
    animation-duration: 0.4s;
    max-height: 11.5rem;
    padding: 0 0.5rem;
    .slick-track {
      display: flex;
      align-items: baseline;
    }
    .slick-arrow {
      ::before {
        content: unset !important;
      }

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      z-index: 3;
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-slide {
      display: flex;
      padding-right: 0.5rem;
      cursor: pointer;
    }
  }

  @keyframes ball-bounce {
    0% {
      height: 0rem;
    }
    25% {
      height: 2.5rem;
    }
    50% {
      height: 5rem;
    }
    75% {
      height: 7.5rem;
    }
    100% {
      height: 10rem;
    }
  }
`;

export const DropDownWrapper = styled.div`
  position: absolute;
  top: 3.5rem;
  background-color: #f1f1f1;
  transition: height 0.4s ease-in-out;
  width: 100%;
  min-width: 300px;
  left: 0;
  /* height: auto; */
  /* max-height: 400px; */
  overflow-y: scroll;

  @media ${device.onlyMobileSm} {
    top: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--grey);
    height: 2px;
  }

  .item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    padding-top: 20px;
    text-align: left;
    font-weight: 400;
    border-bottom: 1px solid rgba(179, 154, 106, 0.2);
    @media ${device.tablet} {
      border-bottom: 1px solid var(--lightGrey3);
    }
    padding-bottom: 10px;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 200px;
  display: flex !important;
  justify-content: center;
  padding: 1rem 0;
  align-items: center;
  height: 15rem;
  flex-direction: column;
  animation-name: ball-bounce;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;

  .inner-wrapper {
    position: relative;
    /* margin-top: 1rem; */
    height: 100%;
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 11.5rem;
      /* max-height: 115px; */
      @media ${device.onlyMobileSm} {
        height: 250px;
      }
    }
  }

  .room-name {
    width: 100%;
    padding-top: 0.5rem;
    text-align: left;
    font-size: 1.3rem;
    color: black;
  }

  .room-code {
    font-size: 1.1rem;
    width: 100%;
    opacity: 0.8;
    padding-top: 0.5rem;
    text-align: right;
    color: var(--darkGrey);
  }
`;

export const StyledList = styled.li`
  position: relative;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.16667em;
  text-decoration: none;
  transition: opacity 0.4s;
  font-family: "Riviera Nights", "Gill Alt One MT", Helvetica, Arial,
    -apple-system, sans-serif;
  :not(.page-title, .divider, .hamburger-wrapper, .logo-text-wrapper) {
    align-self: center;
    margin-left: 20px;
  }

  &.page-title,
  &.hasSubNav {
    display: flex;
    align-items: center;
    svg {
      margin-top: -4px;
      filter: brightness(0.5) invert(1);
      transform: rotate(0);
      transition: all 0.4s ease-in-out;
    }
    .list-icon {
      @media ${device.onlyMobileSm} {
        margin-left: -7px !important;
      }
    }
  }
  &.rotate {
    svg {
      transform: rotate(180deg);
    }
  }
  text-transform: uppercase;
  :hover {
    cursor: pointer;
  }
  .vertical-divider {
    display: flex;
    align-items: center;
    top: -1px;
    position: relative;
    @media ${device.onlyMobileSm} {
      color: black;
    }
  }
  ${activeStyles}
`;
