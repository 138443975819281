import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import {
  ScrollElement as scrollElement,
  Link as ScrollLink,
} from "react-scroll";
import {
  LogoTextWrapper,
  NavWrapper,
  SecondaryNavBarWrapper,
  DropDownWrapper,
  ImageWrapper,
  StyledList,
} from "./elements";
import { Link, navigate } from "gatsby";
import HamburgerIcon from "../../assets/icons/menu-solid.svg";
import ChevronDown from "../../assets/icons/chevron-down.svg";
import Image from "gatsby-plugin-sanity-image";
import { GatsbySanityImage } from "../GatsbySanityImage";
import { useScoll, useScrollToRef, useIsMobile, useNavBar } from "../../hooks";
import {
  COLLECTIONS,
  HOME,
  RESORTS,
  MAGAZINE,
  SIMPLE_MAIN_NAVBAR,
  SCROLL_DOWN,
  SCROLL_UP,
} from "../../constants";
import { Carousel } from "../Carousel";
import { getResortUrl } from "../../lib";

export const NavBar = ({
  onMenuClick,
  sideWideNavContent,
  navLinks: navLinks_,
  heroRef,
  pageName,
  activeNavLink,
}) => {
  const maxScroll = heroRef?.current?.clientHeight || 200;

  const [_, scrollPosition, scrollDir] = useScoll({
    scrollHeightToHide: undefined,
    scrollHeightToShow: undefined,
  });

  const isMobile = useIsMobile();
  const navLinks = isMobile
    ? navLinks_?.length
      ? navLinks_?.filter(({ visibleOnMobile }) => !!visibleOnMobile)
      : []
    : navLinks_;

  const isMaxScroll = isMobile
    ? scrollDir === SCROLL_DOWN
      ? false
      : scrollDir === SCROLL_UP
      ? true
      : true
    : scrollPosition < maxScroll / 2;
  const renderNavBar = () => {
    switch (pageName) {
      case SIMPLE_MAIN_NAVBAR:
        return (
          <NavWrapper
            className={`${isMaxScroll ? "black-bg" : "blur-bg"}`}
            bottomNav={isMobile && scrollPosition === 0}
            style={{
              height: "6rem",
              ...(isMobile && !isMaxScroll ? { display: "none" } : {}),
            }}
          >
            <SideWideNavBar
              showSiteWideNav
              onMenuClick={onMenuClick}
              sideWideNavContent={sideWideNavContent}
              className={isMaxScroll ? "black-bg" : ""}
              isMaxScroll={!isMaxScroll}
              scrollPosition={scrollPosition}
              showTopNav
            />
          </NavWrapper>
        );
      default:
        return (
          <DefaultNavBar
            maxScroll={maxScroll}
            navLinks={navLinks}
            isMobile={isMobile}
            onMenuClick={onMenuClick}
            sideWideNavContent={sideWideNavContent}
            scrollPosition={scrollPosition}
            isMaxScroll={isMaxScroll}
            activeNavLink={activeNavLink}
          />
        );
    }
  };
  return renderNavBar();
};

const DefaultNavBar = ({
  navLinks,
  onMenuClick,
  sideWideNavContent,
  isMobile,
  isMaxScroll,
  scrollPosition,
  activeNavLink,
}) => {
  const { activeResortName } = useNavBar();
  // const [activeLink, setActiveLink] = useState(null);
  const [showSiteWideNav, setShowSiteWideNav] = useState(false);
  const [showMainNav, setShowMainNav] = useState(false);
  const [mainNavContent, setMainNavContent] = useState([]);
  const [showMainNavDropDown, setShowMainNavDropDown] = useState(false);
  const isAccomodation = mainNavContent[0]
    ? "thumbImage" in mainNavContent[0]
    : false;
  useEffect(() => {
    if (showSiteWideNav || setShowMainNav || showMainNavDropDown) {
      setShowSiteWideNav(false);
      setShowMainNav(false);
      setShowMainNavDropDown(false);
    }
  }, [scrollPosition]);

  // useEffect(() => {
  //   setActiveLink(activeNavLink);
  // }, [activeNavLink]);

  const onIconClick = useCallback(
    ({ hasSubNav, isDropDown, name, content }) => {
      // const isActive = activeLink === name;
      if (hasSubNav) {
        setShowMainNav(!showMainNav);
        setMainNavContent(content);
        setShowSiteWideNav(false);
      }
      if (isDropDown) {
        if (isMobile) {
          setShowMainNav(!showMainNav);
          setMainNavContent(content);
        } else {
          setShowMainNavDropDown(!showMainNavDropDown);
          // setActiveLink(!isActive ? name : "");
        }
      }
    },
    [showMainNavDropDown, showMainNav, isMobile]
  );

  const onItemClick = useCallback(({ name, onClick }) => {
    onClick?.();
  }, []);

  let navLinkContainerClass = "";
  const navLinks_ =
    navLinks?.length &&
    navLinks?.map(
      (
        { name, innerRef, hasSubNav, content, isDropDown, onClick, link },
        index
      ) => {
        navLinkContainerClass = "full-column";
        // const isActive = activeLink === name;
        return {
          sibling: index > 0 ? <>|</> : null,
          siblingClassName: "vertical-divider",
          className: !onClick
            ? hasSubNav
              ? `hasSubNav ${showMainNav ? "rotate" : ""}`
              : ""
            : isDropDown
            ? `hasDropDown ${
                showMainNavDropDown || (isMobile && showMainNav) ? "rotate" : ""
              }`
            : "",
          icon: hasSubNav || isDropDown ? <ChevronDown /> : undefined,
          onIconClick: () =>
            onIconClick({ hasSubNav, isDropDown, name, content }),
          onClick: () =>
            hasSubNav || isDropDown
              ? onIconClick({ hasSubNav, isDropDown, name, content })
              : onItemClick({ name, innerRef, onClick, isDropDown }),
          content: (
            <ScrollLink
              className="text"
              activeClass="active"
              to={link || name}
              spy={true}
              smooth={true}
              onClick={onClick}
            >
              {name}
              {!hasSubNav && !isMobile && (
                <DropDown
                  className="dropdown"
                  listItems={content}
                  sectionOffset={80}
                  styles={{
                    height: isDropDown && showMainNavDropDown ? "400px" : "0px",
                  }}
                  onListClick={(e) => {
                    e.stopPropagation();
                    // setActiveLink("");
                  }}
                />
              )}
            </ScrollLink>
          ),
        };
      }
    );

  const navBarStyle = { height: "5.8rem" };
  return (
    <NavWrapper
      className={`${isMaxScroll ? "black-bg" : "blur-bg"}`}
      bottomNav={isMobile && scrollPosition === 0}
      style={{
        ...navBarStyle,
        ...(isMobile && !isMaxScroll ? { display: "none" } : {}),
      }}
    >
      {isMaxScroll || isMobile ? (
        <NavBarList
          className="container"
          items={[
            {
              className: "hamburger-wrapper",
              content: <HamburgerIcon />,
              onClick: onMenuClick,
            },
            {
              className: "logo-text-wrapper",
              content: <LogoTextWrapper>BOUNDLESS MALDIVES</LogoTextWrapper>,
              onClick: () => navigate("/"),
            },
            !isMobile ? { className: "divider", content: <></> } : {},
          ]}
        />
      ) : null}

      {!isMobile ? (
        <NavWrapper
          className={`${
            isMaxScroll ? "black-bg" : isMobile ? "blur-bg" : ""
          } secondary-nav `}
          style={navBarStyle}
          bottomNav
        >
          <NavBarList
            items={[
              {
                className: `${showSiteWideNav ? "rotate" : ""} page-title`,
                icon: <ChevronDown />,
                content: <div className="text">Boundless</div>,
                onClick: () => {
                  setShowSiteWideNav(!showSiteWideNav);
                  setShowMainNav(false);
                },
              },
            ]}
            className={`container ${navLinkContainerClass} ${
              activeResortName ? "three-columns" : ""
            }`}
          >
            {navLinks?.length ? (
              <NavBarList items={navLinks_} className="bottom-links" />
            ) : null}
          </NavBarList>

          <SideWideNavBar
            showSiteWideNav={showSiteWideNav}
            sideWideNavContent={sideWideNavContent}
            onMenuClick={onMenuClick}
          />
          <SecondaryNavBar
            height={isAccomodation ? "17rem" : `10rem`}
            open={showMainNav}
            listItems={mainNavContent}
          />
        </NavWrapper>
      ) : isMaxScroll ? (
        <NavWrapper
          className={`${
            isMaxScroll ? "black-bg" : isMobile ? "blur-bg" : ""
          } secondary-nav bg-white `}
          bottomNav
        >
          <NavBarList
            items={[
              activeResortName
                ? {
                    className: "",
                    content: (
                      <div className="text resort-name">{activeResortName}</div>
                    ),
                    onClick: () => {
                      const url = getResortUrl({ shortName: activeResortName });
                      navigate(url);
                    },
                  }
                : null,
            ]}
            className={`container  ${navLinkContainerClass}`}
          >
            {navLinks_?.length ? (
              <NavBarList
                items={navLinks_}
                className={`bottom-links three-columns ${
                  navLinks_?.length > 1 ? "align-items-center" : ""
                }`}
              />
            ) : null}
          </NavBarList>
          <div style={{ position: "relative" }}>
            <DropDown
              listItems={mainNavContent}
              isActive={showMainNav}
              sectionOffset={130}
              styles={{ height: showMainNav ? "calc(100vh - 90px)" : "0px" }}
              onListClick={(e, _, onClick) => {
                if (onClick) onClick();
                e.stopPropagation();
              }}
            />
          </div>
        </NavWrapper>
      ) : null}
    </NavWrapper>
  );
};

const SideWideNavBar = ({
  showSiteWideNav,
  sideWideNavContent,
  onMenuClick,
  showTopNav,
  isMaxScroll,
  scrollPosition,
  className,
}) => {
  const [activeSiteWideNavLink, setActiveSiteWideNavLink] = useState(null);
  const [showSiteWideNavContent, setShowSiteWideContent] = useState(false);
  const isMobile = useIsMobile();

  const siteWideNavContent_ = (
    activeSiteWideNavLink
      ? activeSiteWideNavLink === COLLECTIONS
        ? sideWideNavContent.collections
        : activeSiteWideNavLink === RESORTS
        ? sideWideNavContent.resorts.slice(0, 5)
        : activeSiteWideNavLink === MAGAZINE
        ? sideWideNavContent.posts
        : []
      : []
  ).map(({ name, url }, index) => {
    return {
      className: index === 0 ? "page-title" : "",
      content: name,
      onClick: () => onSiteWideNavContentClick(url),
    };
  });
  const onSiteWideNavContentClick = (url) => {
    navigate(url);
  };

  const onSiteWideNavLinkClick = (linkName) => {
    if (activeSiteWideNavLink === linkName) {
      setShowSiteWideContent(false);
      setActiveSiteWideNavLink(null);
    } else {
      setActiveSiteWideNavLink(linkName);
      setShowSiteWideContent(true);
    }
  };

  const isActiveHome = activeSiteWideNavLink === HOME;
  const isActiveCollections = activeSiteWideNavLink === COLLECTIONS;
  const isActiveResort = activeSiteWideNavLink === RESORTS;
  const isActiveMagazine = activeSiteWideNavLink === MAGAZINE;

  const listItems = [
    {
      className: `${isActiveHome ? "active" : ""} page-title`,
      content: HOME,
      onClick: () => {
        navigate("/");
      },
    },
    {
      className: isActiveResort ? "rotate" : "",
      icon: !isMobile ? <HamburgerIcon /> : null,
      content: <div className="text">{RESORTS}</div>,
      onClick: (e) => onMenuClick(e, RESORTS),
    },
    {
      className: isActiveCollections ? "rotate" : "",
      icon: !isMobile ? <ChevronDown /> : null,
      content: <div className="text">{COLLECTIONS}</div>,
      onClick: () => onSiteWideNavLinkClick(COLLECTIONS),
    },
    {
      className: isActiveMagazine ? "rotate" : "",
      content: <div className="text">{MAGAZINE}</div>,
      icon: !isMobile ? <ChevronDown /> : null,
      onIconClick: () => onSiteWideNavLinkClick(MAGAZINE),
      onClick: () => navigate(`/${MAGAZINE.toLowerCase()}`),
    },
  ];

  useLayoutEffect(() => {
    if (showSiteWideNavContent) {
      setShowSiteWideContent(false);
      setActiveSiteWideNavLink(null);
    }
  }, [scrollPosition]);

  return (
    <>
      {(showTopNav && !isMaxScroll) || isMobile ? (
        <NavBarList
          className="container"
          items={[
            {
              className: "hamburger-wrapper",
              content: isMobile ? <HamburgerIcon /> : null,
              onClick: onMenuClick,
            },
            {
              className: "logo-text-wrapper",
              content: <LogoTextWrapper>BOUNDLESS MALDIVES</LogoTextWrapper>,
              onClick: () => navigate("/"),
            },
            !isMobile ? { className: "divider", content: <></> } : {},
          ]}
        />
      ) : null}
      {!isMobile ? (
        <React.Fragment>
          <SecondaryNavBar
            open={showSiteWideNav}
            listWrapperClass={
              showSiteWideNavContent ? "second-nav-border-bottom" : ""
            }
            className={className}
            listItems={listItems}
            height={isMobile ? "20rem" : undefined}
          />
          <SecondaryNavBar
            open={showSiteWideNavContent && showSiteWideNav}
            listItems={siteWideNavContent_}
            height={isMobile ? "25rem" : undefined}
          />
        </React.Fragment>
      ) : null}
    </>
  );
};

export const SecondaryNavBar = ({
  open,
  listItems,
  listWrapperClass,
  isDropDown,
  className,
  height,
}) => {
  return (
    <SecondaryNavBarWrapper
      style={{
        height: open ? height || "6rem" : "0rem",
      }}
      className={className}
    >
      {open ? (
        <NavBarList
          className={`${listWrapperClass ? listWrapperClass : ""} container`}
          items={listItems}
        />
      ) : null}
    </SecondaryNavBarWrapper>
  );
};

const NavBarList = ({ items, className, children }) => {
  const thumbImage = items[0] ? "thumbImage" in items?.[0] : false;
  return thumbImage ? (
    <Carousel
      className="carousel"
      variableWidth={true}
      centerMode={false}
      speed={1000}
      infinite={false}
      slidesToShow={items?.length < 6 ? items?.length : 6}
      slidesToScroll={3}
      swipe={false}
      swipeToSlide={false}
      dots={false}
    >
      {items?.length
        ? items?.map((item, key) => {
            const { thumbImage, content, onClick } = item;
            return (
              thumbImage?.asset && (
                <RoomImage
                  key={key}
                  image={thumbImage}
                  content={content}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick?.();
                  }}
                />
              )
            );
          })
        : null}
      {children}
    </Carousel>
  ) : (
    <ul className={className}>
      {items.map((item, index) => {
        if (!item || (item ? !Object.keys(item).length : false)) return null;
        const {
          className: className_,
          icon,
          content,
          sibling,
          siblingClassName,
          thumbImage,
          onClick,
          onIconClick,
        } = item;
        return (
          <React.Fragment key={index}>
            {sibling ? (
              <StyledList className={siblingClassName}>{sibling}</StyledList>
            ) : null}
            <StyledList
              className={`${className_} ${thumbImage ? "image-wrapper" : ""}`}
              onClick={onClick}
            >
              {icon ? (
                <div
                  className="list-icon"
                  onClick={(e) => {
                    if (onIconClick) {
                      e.stopPropagation();
                      onIconClick?.();
                    }
                  }}
                >
                  {icon}
                </div>
              ) : null}

              {content}
            </StyledList>
          </React.Fragment>
        );
      })}
      {children}
    </ul>
  );
};

export const Logo = ({ logo }) => (
  <div className="logo">
    <Link to="/">
      {logo && logo.asset && (
        <Image
          asset={logo.asset}
          width={100}
          height={150}
          config={{ fit: "clip" }}
          alt={logo.alt}
        />
      )}
    </Link>
  </div>
);

const DropDown = ({
  listItems: listItems_,
  onListClick,
  sectionOffset,
  className,
  styles,
}) => {
  if (!listItems_ || !listItems_.length) return null;
  const listItems = listItems_?.sort((a, b) => {
    const aName = a?.name || a?.content?.name;
    const bName = b?.name || b?.content?.name;
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  return (
    <DropDownWrapper
      style={{
        ...styles,
      }}
      className={className}
    >
      {listItems?.length
        ? listItems?.map(({ name, content, innerRef, onClick }) => {
            return (
              <div
                key={name}
                className="item"
                onClick={(e) => onListClick(e, innerRef, onClick)}
              >
                {!onClick ? (
                  <ScrollLink
                    to={name}
                    spy={true}
                    smooth={true}
                    offset={-sectionOffset}
                  >
                    {name || content?.name}
                  </ScrollLink>
                ) : (
                  name || content?.name
                )}
              </div>
            );
          })
        : null}
    </DropDownWrapper>
  );
};

export const RoomImage = ({ image, content, onClick }) => {
  return (
    <ImageWrapper onClick={onClick}>
      <div className="inner-wrapper">
        <GatsbySanityImage
          gatsbyImage={image?.asset?.gatsbyImageData}
          alt={image?.alt}
        />
      </div>
      <div className="room-name">{content?.name}</div>
      {content?.uniqueCode ? (
        <div className="room-code">{`# ${content?.uniqueCode}`}</div>
      ) : null}
    </ImageWrapper>
  );
};
